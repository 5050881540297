const initialState = {
  aiModels: [],
  activeAiModels: [],
  isAiModelConfigUpdating: {},
  aiModelConfigs: {},
};

const aiConfigurations = (state = initialState, action) => {
  switch (action.type) {
    case "SET_AI_MODELS": {
      return {
        ...state,
        aiModels: action.payload,
      };
    }
    case "SET_ACTIVE_AI_MODELS": {
      return {
        ...state,
        activeAiModels: action.payload,
      };
    }
    case "SET_AI_MODEL_CONFIGS": {
      const { aiModelId, data } = action.payload;
      return {
        ...state,
        aiModelConfigs: {
          ...state.aiModelConfigs,
          [aiModelId]: { ...state.aiModelConfigs[aiModelId], ...data },
        },
      };
    }
    case "IS_AI_MODEL_CONFIG_UPDATING": {
      const { config, loading } = action.payload;
      return {
        ...state,
        isAiModelConfigUpdating: {
          ...state.isAiModelConfigUpdating,
          [config]: loading,
        },
      };
    }
    case "UPDATE_AI_MODEL_CONFIGS": {
      const { id, aiModelId, data } = action.payload;
      return {
        ...state,
        aiModelConfigs: {
          ...state.aiModelConfigs,
          [aiModelId]: {
            ...state.aiModelConfigs[aiModelId],
            [id]: data,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default aiConfigurations;

import ReactGA from "react-ga";
import ahoyJs from "ahoy.js";
import { APP_ENV, API_ROOT, CHATWOOT_TOKEN } from "../config/api-config";
import headers from "actions/headers.js";
import posthog from "posthog-js";

export const initGA = () => {
  if (APP_ENV === "PRODUCTION") {
    ReactGA.initialize([
      { trackingId: "UA-149150790-1" },
      { trackingId: "AW-789448602" },
    ]);
  }
};

export const PageView = () => {
  if (APP_ENV === "PRODUCTION") {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};

export const Event = (category, action, label) => {
  if (APP_ENV === "PRODUCTION") {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  }
};

export const initAhoy = () => {
  const config = {
    urlPrefix: API_ROOT,
    ...headers(),
  };
  ahoyJs.configure(config);

  return ahoyJs;
};

const postHogServer = {
  PRODUCTION: "PRODUCTION",
  DEMO: "DEMO",
  ALPHA: "ALPHA",
};

export const isPostHogAvailable = postHogServer[APP_ENV];

export const initPostHog = (user) => {
  if (!isPostHogAvailable) {
    return;
  }

  posthog.init("phc_yGMWcEL7GYloGATrfjYd0v7L1M15xNAtAOhEov4yD3T", {
    api_host: "https://d1ukqr1iizj1k9.cloudfront.net",
    disable_session_recording: true,
    opt_in_site_apps: true,
    persistence: "localStorage",
  });

  posthog.onFeatureFlags(function () {
    if (posthog.isFeatureEnabled("enable_session_recording")) {
      posthog.startSessionRecording();
    }
  });

  if (typeof user !== "undefined") {
    const { employeeName, userEmail, companyName } = user;
    const customData = {
      name: employeeName,
      company: companyName,
      environment: APP_ENV,
    };
    if (typeof userEmail !== "undefined" && userEmail !== null) {
      posthog.identify(userEmail, customData);
    }
  }
};

export const posthogCaptureEvent = (eventName, eventData) => {
  if (APP_ENV === "PRODUCTION") {
    posthog.capture(eventName, eventData);
  }
};

export const ChatwootAPI = () => ({
  ...window.$chatwoot,
  init: () => {
    window.chatwootSettings = {
      hideMessageBubble: APP_ENV !== "PRODUCTION" && APP_ENV !== "DEMO",
    };

    (function (d, t) {
      var BASE_URL = "https://cleartalk.peoplebox.ai";
      var g = d.createElement(t);
      var s = d.getElementsByTagName(t)[0];
      g.src = BASE_URL + "/packs/js/sdk.js";
      g.defer = true;
      g.async = true;
      s.parentNode.insertBefore(g, s);

      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: CHATWOOT_TOKEN,
          baseUrl: BASE_URL,
          showPopoutButton: false,
        });
      };
    })(document, "script");
  },
});

export const FrillSDK = {
  init: () => {
    const w = window;
    const d = document;
    const frillLauncherElementId = "#peoplebox-frill-launcher";
    w.Frill_Config = window.Frill_Config || [];
    w.Frill_Config.push({
      key: "50859e03-a101-4edb-bc4f-7f97bafefe1e",
      settings: { launcher: { selector: frillLauncherElementId } },
    });
    const s = "script";
    function l() {
      var f = d.getElementsByTagName(s)[0];
      var e = d.createElement(s);
      e.type = "text/javascript";
      e.async = true;
      e.src = "https://widget.frill.co/v2/widget.js";
      f.parentNode.insertBefore(e, f);
    }
    if (d.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  },
};

export const ahoy = ahoyJs;

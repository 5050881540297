import { SELECT_EMPLOYEES_LIST_SUCCESS } from "actions/constants";

const initialState = {
  employeeListObj: null,
  employeeListArray: null,
  idpTrackPositions: {},
};

const selectEmployeeList = (state = initialState, action) =>
  action.type === SELECT_EMPLOYEES_LIST_SUCCESS
    ? { ...state, ...action.payload }
    : state;

export default selectEmployeeList;

import { EMPLOYEES_LIST_SUCCESS, ADD_NEW_EMPLOYEES } from "actions/constants";

const initialState = {
  employeeListObject: null,
  employeeListIdsArray: null,
  idpTrackPositions: {},
};

const employeeListSetting = (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYEES_LIST_SUCCESS: {
      const newState = Object.assign({}, state, {
        ...action.payload,
      });
      return newState;
    }

    case ADD_NEW_EMPLOYEES: {
      const newState = {
        ...state,
        employeeListObject: {
          ...action.payload.employeeListObject,
          ...state.employeeListObject,
        },
        employeeListIdsArray: [
          ...action.payload.employeeListIdsArray,
          ...state.employeeListIdsArray,
        ],
      };
      return newState;
    }

    default:
      return state;
  }
};

export default employeeListSetting;

import { combineReducers } from "redux";
import aiConfigurations from "./AiConfigurations/reducer";
import credits from "./Credits/reducer";
import reviewsRewriteWithAi from "./ReviewsRewriteWithAi/reducer";

const aiFeatures = combineReducers({
  credits,
  aiConfigurations,
  reviewsRewriteWithAi,
});

export default aiFeatures;

export const ALIGN_GOAL_TEXT = "Don't add as a Key Result";
export const NEW_USER_GOAL_TEXT = "No Goals present";
export const EMPTY_GOAL_TEXT = `Achieve 10x performance by setting meaningful & aligned Goals`;
export const ALIGN_GOAL_TOOLTIP_TEXT = `Checking this option will result in aligning of this goal to the parent goal & will not contribute to the progress of the parent goal.`;
export const EXPLORE_NO_GOALS_TEXT =
  "We couldn't find any Goals matching these filters";
export const DEFAULT_GOAL_STATUS = "not_started";
export const INIT_GOAL_STATUS = "on_track";
export const GOAL_STATUS_LIST = {
  [INIT_GOAL_STATUS]: {
    type: INIT_GOAL_STATUS,
    text: "on track",
    id: 1,
    statusType: "open",
  },
  at_risk: {
    type: "at_risk",
    text: "at risk",
    id: 3,
    statusType: "open",
  },
  [DEFAULT_GOAL_STATUS]: {
    type: DEFAULT_GOAL_STATUS,
    text: "not started",
    id: 0,
    statusType: "open",
  },
  behind: {
    type: "behind",
    text: "behind",
    id: 2,
    statusType: "open",
  },
  achieved: {
    type: "achieved",
    text: "achieved",
    id: 4,
    statusType: "close",
  },
  not_achieved: {
    type: "not_achieved",
    text: "not achieved",
    id: 5,
    statusType: "close",
  },
};

export const getDaysInMonth = function (month, year) {
  return new Date(year, month, 0).getDate();
};

export const findQuarterRangeText = (quarter) => {
  const month = [
    "Empty",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const startdate = quarter.start_date.split("T")[0];
  const monthCodeStart = parseInt(startdate.split("-")[1]);
  const enddate = quarter.end_date.split("T")[0];
  const yearCodeEnd = parseInt(enddate.split("-")[0]);
  const monthCodeEnd = parseInt(enddate.split("-")[1]);
  return `1 ${month[monthCodeStart]} - ${getDaysInMonth(
    monthCodeEnd,
    yearCodeEnd
  )} ${month[monthCodeEnd]}`;
};

export const formatFilterData = (filter, rawFilter) => {
  const filterData = {};
  Object.keys(filter).forEach((filterType) => {
    const { type } = rawFilter[filterType];

    filterData[type] = Object.keys(filter[filterType]).map(
      (filterId) => filter[filterType][filterId].id
    );
  });

  return filterData;
};

export const generateGoalFilterData = () => {
  const alignmentType = {
    text: "alignment type",
    children: {
      /*
      will add when backend supports
      aligned_goals: {
        text: "aligned goals only",
      },
      key_result_goals: {
        text: "key results only",
      }, */
    },
  };

  const status = {
    text: "status",
    type: "status",
    children: {
      ...GOAL_STATUS_LIST,
    },
  };

  const employees = {
    text: "goal owner",
    type: "employees",
    children: {},
  };

  const departments = {
    text: "department",
    type: "departments",
    children: {},
  };

  const goalType = {
    text: "goal type",
    type: "goal_type",
    children: {
      individual: {
        type: "individual",
        text: "individual",
        id: 0,
      },
      department: {
        type: "department",
        text: "department",
        id: 1,
      },
      company: {
        type: "company",
        text: "company",
        id: 2,
      },
    },
  };

  return {
    employees,
    status,
    departments,
    goalType,
    alignmentType,
  };
};

export const WEIGHT_INFO = `Weighted goals allow goal owners to customize & communicate the relative importance of each key result. Owners can customize how much each child impacts its parents' progress.`;

export const _renderGoalUpdateText = (
  type,
  oldProgress,
  newProgress,
  isToggleChecked
) => {
  const defaultUpdateText = `Updated progress from ${oldProgress} to ${newProgress}`;

  const goalUpdateTextObject = {
    dollar: `Updated progress from $${oldProgress} to $${newProgress}`,
    GBP: `Updated progress from £${oldProgress} to £${newProgress}`,
    BDT: `Updated progress from ৳${oldProgress} to ৳${newProgress}`,
    SAR: `Updated progress from ${oldProgress}﷼ to ${newProgress}﷼`,
    AED: `Updated progress from ${oldProgress}د.إ to ${newProgress}د.إ`,
    number: defaultUpdateText,
    default_progress: `Updated progress from ${oldProgress}% to ${newProgress}%`,
    percentage: `Updated progress from ${oldProgress}% to ${newProgress}%`,
    indian_rupee: `Updated progress from ₹${oldProgress} to $${newProgress}`,
    euro: `Updated progress from €${oldProgress} to €${newProgress}`,
    binary: !isToggleChecked
      ? `Updated progress to Incomplete`
      : `Updated progress to Complete`,
  };

  return goalUpdateTextObject[type] || defaultUpdateText;
};

const BIZ_REVIEW_DEFAULT_KEY = "this_week";
export const BIZ_REVIEW_DEFAULT = {
  type: BIZ_REVIEW_DEFAULT_KEY,
  text: "This Week",
};

export const BIZ_REVIEW_TIMELINE = {
  [BIZ_REVIEW_DEFAULT_KEY]: BIZ_REVIEW_DEFAULT,
  last_week: {
    type: "last_week",
    text: "Last Week",
  },
  last_two_week: {
    type: "last_two_week",
    text: "Last 2 Weeks",
  },
  this_month: {
    type: "this_month",
    text: "This Month",
  },
  last_month: {
    type: "last_month",
    text: "Last Month",
  },
};

export const getSortedKeyIdList = (keyIdListObject, goalObjectList) => {
  const sortedKeyIdListObject = {};

  Object.keys(keyIdListObject).forEach((parentGoalId) => {
    const keyIdList = keyIdListObject[parentGoalId];
    let regularGoals = [];
    let alignedGoals = [];
    let projectGoals = [];

    keyIdList
      .sort((a, b) => parseInt(a) - parseInt(b))
      .forEach((keyId) => {
        if (goalObjectList[keyId]?.objective_type === "project") {
          projectGoals = [...projectGoals, keyId];
        } else {
          const isAlignedGoal = goalObjectList[keyId]?.goal_hierarchies?.some(
            (i) => i.objective_type === "alignment"
          );
          if (isAlignedGoal) {
            alignedGoals = [...alignedGoals, keyId];
          } else {
            regularGoals = [...regularGoals, keyId];
          }
        }
      });

    sortedKeyIdListObject[parentGoalId] = [
      ...regularGoals,
      ...alignedGoals,
      ...projectGoals,
    ];
  });

  return sortedKeyIdListObject;
};

export const NANO_ID_LENGTH = 14;

export const GOAL_HIERARCHIES_VERBOSE = {
  key_result: 1,
  project: 2,
  alignment: 3,
};

export const GOAL_SECONDARY_FORM_ELEMENTS = {
  visibility: "visibility",
  milestones: "milestones",
  progressIntegration: "progress_integration",
};

export const GOAL_VISIBILITY_ENUM = {
  public: "open",
  private: "restricted",
  custom: "custom",
};

export const GOAL_CHECKIN_TYPE_ENUM = {
  manual: "manual",
  autoRollUp: "auto_roll_up",
  externalSource: "external_source",
};

export const GOAL_MILESTONES_SUMMARY_ENUM = {
  sum: "summation",
  average: "average_value",
  lastValue: "last_value",
};

export const GOAL_PROGRESS_ROLLUP_VALIDATION_ERROR = {
  key_result:
    "When progress rolls up from children, the combined progress of Key Results of the same type(e.g., numeric or currency) can't exceed their Parent Goal's progress. Check your KRs.",
  key_result_and_alignment:
    "When progress rolls up from children, the combined progress of Key Results of the same type(e.g., numeric or currency) can't exceed their Parent Goal's progress. Check your KRs and alignment.",
  alignment:
    "When progress rolls up from children, the combined progress of Key Results of the same type(e.g., numeric or currency) can't exceed their Parent Goal's progress. Please check goal alignment.",
};

export const DISABLED_GOAL_CYCLE_TOOLTIP_TEXT =
  "A goal's cycle can't be edited for a goal with milestones, please remove milestones to edit the cycle";

export const GOAL_TEMPLATES_FETCH_STATUS_ENUM = {
  FETCHING: "fetching",
  FETCHED: "fetched",
  NOT_FETCHED: "notFetched",
};

const initialState = {
  rewriteWithAiModalVisible: false,
  rewriteWithAiModalContent: "",
  parentEditor: null,
  propsForRewriteWithAi: {},
};

const reviewsRewriteWithAi = (state = initialState, action) => {
  switch (action.type) {
    case "SET_REWRITE_WITH_AI_MODAL_VISIBLE": {
      const { visible, parentEditor, propsForRewriteWithAi } = action.payload;
      return {
        ...state,
        rewriteWithAiModalVisible: visible,
        parentEditor: parentEditor,
        propsForRewriteWithAi: propsForRewriteWithAi,
      };
    }
    case "SET_REWRITE_WITH_AI_MODAL_CONTENT": {
      return {
        ...state,
        rewriteWithAiModalContent: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reviewsRewriteWithAi;

const initialState = {
  creditBalance: {},
};

const credits = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CREDIT_BALANCE": {
      return {
        ...state,
        creditBalance: action.payload,
      };
    }
    default:
      return state;
  }
};

export default credits;

import {
  GET_REVIEWEES_LIST,
  SET_CALIBRATIONS_VIEW_DATA,
  UPDATE_MANAGER_REVIEW_ACTIVE_TAB,
} from "actions/constants";

const initialState = {
  calibrationViewData: {
    columns: [],
    reviewees: [],
    pagination_data: {},
  },
  managerReviewActiveTab: "writeReview",
};

const WriteReviews = (state = initialState, action) => {
  switch (action.type) {
    case GET_REVIEWEES_LIST: {
      return {
        ...state,
        revieweesList: { ...action.payload },
      };
    }

    case SET_CALIBRATIONS_VIEW_DATA: {
      return {
        ...state,
        calibrationViewData: { ...action.payload },
      };
    }
    case UPDATE_MANAGER_REVIEW_ACTIVE_TAB: {
      return {
        ...state,
        managerReviewActiveTab: action.payload,
      };
    }
    default:
      return state;
  }
};

export default WriteReviews;

import {
  SET_CUSTOM_GOAL_TEMPLATES_ADDED,
  SET_GOAL_TEMPLATES,
  SET_GOAL_TEMPLATES_FETCH_STATUS,
  SET_GOAL_TEMPLATE_CATEGORIES,
} from "actions/constants";
import { GOAL_TEMPLATES_FETCH_STATUS_ENUM } from "constants/goal.constants";

const initialState = {
  templatesFetchStatus: GOAL_TEMPLATES_FETCH_STATUS_ENUM.NOT_FETCHED,
  isCustomGoalTemplatesAdded: false,
  goalTemplates: [],
  goalTemplateCategories: [],
};

const goalTemplates = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOM_GOAL_TEMPLATES_ADDED: {
      return {
        ...state,
        isCustomGoalTemplatesAdded: action.payload,
      };
    }

    case SET_GOAL_TEMPLATES: {
      return {
        ...state,
        goalTemplates: action.payload,
      };
    }

    case SET_GOAL_TEMPLATE_CATEGORIES: {
      return {
        ...state,
        goalTemplateCategories: action.payload,
      };
    }

    case SET_GOAL_TEMPLATES_FETCH_STATUS: {
      if (
        !Object.values(GOAL_TEMPLATES_FETCH_STATUS_ENUM).includes(
          action.payload
        )
      ) {
        return state;
      }
      return {
        ...state,
        templatesFetchStatus: action.payload,
      };
    }

    default:
      return state;
  }
};

export default goalTemplates;
